<template>
	<div class="container" ref="getheight">
		<div class="relative banner-sec">
			<img src="../assets/market/banner_bg.png" class="banner-bg" />
			<div class="banner-screen" ref="scollView">
				<img src="../assets/market/screen.png" class="screen" />
				<div class="banner-con">
					<div class="flex-row align-center space-between mt24">
						<div class="flex-row align-center">
							<div class="screen-dian screen-dian-1"></div>
							<div class="screen-dian screen-dian-2"></div>
							<div class="screen-dian screen-dian-3"></div>
						</div>
						<img src="../assets/market/screen_icon_1.png" class="screen-icon-1" />
					</div>
					<div class="scroll-Y" id="scollView">
						<div class="flex-row align-start screen-item" :class="index > 0?'mt16':'mt36'"
							v-for="(item,index) in finishs" :key="index">
							<img src="../assets/market/screen_icon_2.png" class="screen-icon-2" />
							<div :class="index == 0?'screen-item-text-1':'screen-item-text'">{{item}}</div>
						</div>
						<div class="flex-column screen-item screen-input-sec relative">
							<div class="screen-item-text-input relative" v-for="(item,index) in inputs" :key="index">
								<img src="../assets/market/screen_icon_3.png" class="screen-icon-3" v-if='index == 0' />
								<div>{{item}}</div>
							</div>
							<div class="screen-item-text-input relative" v-if="words.length > 0">
								<img src="../assets/market/screen_icon_3.png" class="screen-icon-3"
									v-if='inputs.length == 0' />
								<div>{{words}}</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="good-sec">
			<div class="flex-row align-center space-between good-sec-title">
				<img src="../assets/market/left_arrow.png" class="good-sec-name-left" />
				<div class="good-sec-name">项目</div>
				<img src="../assets/market/right_arrow.png" class="good-sec-name-right" />
			</div>
			<div class="list-sec">
				<div v-for="(item,index) in recommend" :key="index" class="good-item2 relative" @click="navUrl(index)">
					<div class="good-item-top relative">
						<template>
							<img src="../assets/left-border.png" class="recommend-left-border" />
							<img src="../assets/right-border.png" class="recommend-right-border" />
							<img src="../assets/top-border.png" class="recommend-top-border" />
							<img src="../assets/bottom-border.png" class="recommend-bottom-border" />
						</template>
						<img :src="item.mainPic" class="good-item-img" />
					</div>
					<img src="../assets/icon-recommend.png" class="icon-recommend" alt="" />
					<!-- <div class="good-item-xian"></div> -->
					<div class="flex-row align-center mt19">
						<div class="good-item-name">{{item.name}}</div>
					</div>

				</div>
			</div>
			<van-list v-model="loading" :immediate-check="immediateCheck" @load="getList" :finished="finished"
				finished-text="" :error.sync="error" error-text="请求失败，点击重新加载">
				<div class="list-sec">
					<div v-for="(item,index) in list" :key="index" class="good-item relative"
						@click="navDetail(item.id,index)">
						<div class="xian xian-1"></div>
						<div class="xian xian-2"></div>
						<div class="good-item-top relative">
							<img :src="item.listViewPic" class="good-item-img" />
						</div>
						<div class="good-item-num" v-if='item.status==1 || item.status==0'>NO.{{item.seq}}</div>
						<!-- <img src="../assets/icon-recommend.png" v-if='item.status==0' class="icon-recommend" alt=""> -->
						<div class="food-item-finish-icon" v-if='item.status ==2'>已结束</div>
						<div class="good-item-xian"></div>
						<div class="flex-row align-center mt19">
							<!-- <img src="../assets/market/new_icon.png" class="new-icon" v-if='true' />
							<img src="../assets/market/hot_icon.png" class="new-icon" v-if='false' /> -->
							<div class="good-item-name">{{item.name}}</div>
						</div>
						<div class="flex-row align-center mt12">
							<div class="good-item-price">￥{{item.price}}</div>
							<!-- <div class="good-item-qi">起</div> -->
						</div>
						<div class="xian xian-3"></div>
						<div class="xian xian-4"></div>
						<!-- <div class="progress-sec">
							<div :class="false?'progress-sec-finish-end':'progress-sec-finish'">
								<div class="progress-l" :style="'left:'+ item.left +'px'"></div>
								<div class="flex-row align-center space-between progress-num-sec">
									<div class="progress-sec-con">
										已筹{{item.soldOutAmount}}元/{{item.quantity-item.surplusQuantity}}人支持
									</div>
									<div class="flex-row align-center" v-if="item.progress >=100">
										<img src="../assets/market/fire_icon.png" class="fire-icon" />
										<div class="progress-sec-num">{{item.progress}}%</div>
									</div>
								</div>
							</div>
						</div> -->
					</div>
					<div class="finished" @click.stop="navApply()" v-if="finished">我要申请</div>
				</div>
			</van-list>
		</div>
		<div class="bot-tab-sec flex-row align-center">
			<div class="bot-tab-item flex-column align-center justify-center bot-tab-item-active">
				<img src="../assets/tabbar/home_tab_selected.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">众筹项目</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center" @click.stop="navCollect()">
				<img src="../assets/tabbar/support_tab.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">我支持的</div>
			</div>
		</div>
		<div class="icp flex-row align-center space-around">
			<div @click.stop="navBa()">沪ICP备17003041号-4</div>
			<div class="flex-row align-center">
				<!-- <img src="../assets/beiab.png" class="beiab-img"> -->
				<div @click.stop="navBa2()">沪公网安备 31011402009626号</div>
			</div>
		</div>
	</div>
</template>
<script>
	import config from '../config/config.js';
	import util from '../config/util.js';
	import Vue from 'vue';
	import Vant from 'vant';
	import 'vant/lib/index.css';

	Vue.use(Vant);
	export default {
		data() {
			return {
				textArrs: [],
				finishs: [], //已经输入完毕的单句数据
				inputs: [], //正在输入的单句已经完成输入的行数据
				words: '', //正在输入的行已经完成输入的文字数据
				pageNum: 1,
				pageSize: 10,
				more: true,
				list: [],
				finished: false,
				error: false,
				loading: false,
				immediateCheck: false,
				xs: 1,
				recommend: []
			}
		},

		methods: {
			navApply() {
				window.location.href = "https://www.wjx.cn/vm/Q01c7uB.aspx"
			},
			navUrl(index) {
				// console.log(index)
				window.location.href = this.recommend[index].recommendUrl;
			},
			getProjectRecommendList() {
				const that = this;
				let params = new FormData()
				params.append('pageNum', 1);
				params.append('pageSize', 40)
				that.axios.post(config.requestUrl + '/front/web3/getProjectRecommendList', params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.recommend = response.data.obj.list
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败');
				})
			},
			navBa2() {
				window.location.href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402009626"
			},
			navBa() {
				window.location.href = "https://beian.miit.gov.cn/#/Integrated/index"
			},
			typing(i, j, m) {
				let that = this;
				let scollView = document.querySelector('#scollView');
				if (m == that.textArrs[i]['arr'][j].length - 1) { //正在输入该行的最后一个字符
					if (j < that.textArrs[i]['arr'].length - 1) { // 当前句还没有输入完毕,继续输入该句的下一行
						// 输入完一行,就需要将整个scroll-div滚动到正在输入的位置 todo
						that.words = '▌'
						that.inputs.push(that.textArrs[i]['arr'][j]);


						scollView.scroll({
							top: 20000
						});
						setTimeout(function() {
							that.typing(i, j + 1, 0)
						}, 10)
					} else { //当前句输入完毕，将finishs添加上一句，将inputs清空
						that.words = ''
						that.inputs.push(that.textArrs[i]['arr'][j]);
						scollView.scroll({
							top: 20000
						});
						if (i < that.textArrs.length - 1) {
							setTimeout(function() {
								that.words = '▌';
								that.inputs = [];
								that.finishs.push(that.textArrs[i]['text']);
								that.typing(i + 1, 0, 0)
							}, 10)
						} else { //所有文字输入完毕,开启新一轮的循环
							that.inputs = [];
							that.finishs.push(that.textArrs[i]['text']);
							scollView.scroll({
								top: 20000
							});
							setTimeout(function() {
								that.words = '▌';
								that.inputs = [];
								that.finishs = [];
								that.typing(0, 0, 0)
							}, 5000)
						}
					}
				} else {
					that.words = that.textArrs[i]['arr'][j].slice(0, ++m) + '▌';
					scollView.scroll({
						top: 20000
					});
					setTimeout(function() {
						that.typing(i, j, m)
					}, 200)
				}
			},
			async navCollect() {
				this.$router.push('/support')
			},
			navDetail(id, index) {
				this.$router.push('/detail?id=' + id + '&index=' + index)
			},
			navSupport() {
				this.$router.repalce('/support')
			},
			getList() {
				const that = this;
				if(that.loading) return;
				let params = new FormData()
				params.append('pageNum', this.pageNum)
				params.append('pageSize', this.pageSize)
				that.axios.post(config.requestUrl + '/front/blindBox/getList', params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let newList = response.data.obj.list;
						let pause = 0;
						let list = [];
						for (let i in newList) {
							//进度条
							let progress = 0;
							if (newList[i].soldOutAmount > 0 && newList[i].quantity > 0 && newList[i].price >
								0) {
								progress = util.accDi(newList[i].soldOutAmount, util.accMul(newList[i]
									.quantity,
									newList[i].price)) * 100
							}
							progress = progress.toFixed(2);
							let left = (-17.5 / 2 + ((677.5 / 2) * progress) / 100) * that.xs
							newList[i].progress = progress;
							newList[i].left = left;
							let endTime = newList[i].endTime.replace(/-/g, "/")
							if (util.getIsShow(endTime)) {
								list.push(newList[i])
							}
						}
						if (list.length < that.pageSize) that.finished = true;
						that.pageNum = that.pageNum + 1;
						that.loading = false
						that.list = that.list.concat(list);
					} else {
						that.error = true
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.error = true
					that.$toast.fail('获取失败');
				})
			},
		},
		mounted() {

			let w = window.screen.width;
			let xs = w / 375;
			this.xs = xs;

			this.pageNum = 1;
			this.pageSize = 10;
			this.more = true;
			this.list = [];

			this.getList();
			this.getProjectRecommendList();
		},
		activated() {
			// 处理banner部分的打字文字A数据
			let texts = [
				'Hello Web3！',
				'Web3已来临，互联网大厂和创业公司都已布局，因为将影响未来十年的商业格局。',
				'Web3是一场生产关系的变革，强调用户主权，用户不仅仅是数据的使用者，更应是数据的所有者。',
				'NFT是Web3世界中，重要的凭证与“门票”。',
				'比如带治理权的NFT，就是把治理权交给用户，让用户“发起提案”并“参与投票”，发挥去中心化的集体智慧，实现双赢！',
			];
			let textArrs = [];
			for (var i in texts) {
				let text = texts[i];
				var len = 0;
				var arr = [];
				var attItem = '';
				for (var j in text) {
					var val = text.charAt(j);
					var len1 = 0;
					if (/^[u0000-u00ff]$/.test(val)) {
						len++;
						len1 = 1;
					} else {
						len += 2;
						len1 = 2;
					}

					if (len > 30) {
						arr.push(attItem)
						attItem = val;
						len = len1;
					} else {
						attItem = attItem + val;
					}
				}
				if (attItem != '') {
					arr.push(attItem)
				}
				textArrs.push({
					'text': text,
					'arr': arr,
				});
			}
			this.textArrs = textArrs;
			this.typing(0, 0, 0);
		},
	}

</script>


<style scoped="scoped">
	body {
		background: #000000;
	}

	.container {
		padding-bottom: 160px;
		background: #000000;
		min-height: 100%;
		width: 100%;
		min-height: 100%;
		overflow: visible;

		box-sizing: content-box;
	}

	/* banner部分开始 */
	.banner-sec {}

	.banner-bg {
		width: 750px;
		height: 667px;
	}

	.banner-screen {
		position: absolute;
		top: 51px;
		left: 20px;
	}

	.screen {
		width: 710px;
		height: 560px;
	}

	.banner-con {
		position: absolute;
		top: 0px;
		left: 0px;
		padding: 0px 50px 0 35px;
		overflow: hidden;
	}

	.scroll-Y {
		height: 480px;
		margin-top: 20px;
		padding-bottom: 10px;
		box-sizing: border-box;
		overflow: auto;
		overflow-anchor: none;

	}

	.scroll-Y::-webkit-scrollbar {
		display: none
	}

	.mt36 {
		margin-top: 36px;
	}

	.mt24 {
		margin-top: 24px;
	}

	.screen-dian {
		width: 15px;
		height: 15px;
		border-radius: 100%;
		margin-right: 12px;
	}

	.screen-dian-1 {
		background: #E02020;
	}

	.screen-dian-2 {
		background: #F7CA00;
	}

	.screen-dian-3 {
		background: #6DD400;
	}

	.screen-icon-1 {
		width: 62px;
		height: 16px;
	}

	.mt16 {
		margin-top: 16px;
	}

	.screen-item {}

	.screen-icon-2 {
		width: 13px;
		height: 16px;
		margin-top: 14px;
		margin-left: 7px;
		margin-right: 20px;
	}

	.screen-item-text {
		width: 593px;
		font-size: 26px;
		font-family: VonwaonBitmap;
		color: #FFFFFF;
		line-height: 43px;
		letter-spacing: 4px;
		margin-left: 2px;
	}

	.screen-item-text-1 {
		max-width: 593px;
		font-size: 26px;
		font-family: VonwaonBitmap;
		color: #FFFFFF;
		line-height: 26px;
		letter-spacing: 4px;
		padding-left: 3px;
		background: #6236FF;
	}

	.screen-input-sec {
		width: 634px;
		min-height: 48px;
		margin-top: 20px;
	}

	.screen-icon-3 {
		position: absolute;
		width: 13px;
		height: 16px;
		top: 16px;
		left: 7px;
		margin-right: 14px;
	}

	.screen-item-text-input {
		font-size: 34px;
		font-family: VonwaonBitmap;
		color: #000000;
		line-height: 48px;
		letter-spacing: 5px;
		background: #6DD400;
		padding-left: 38px;
		margin-top: 12px;
	}

	/* banner部分结束 */

	/* 项目列表开始 */
	.good-sec-title {
		width: 750px;
		height: 84px;
		background: linear-gradient(90deg, rgba(155, 102, 255, 0) 0%, #4600FF 49%, rgba(98, 54, 255, 0) 100%);
		padding: 0 66px;
		box-sizing: border-box;
	}

	.good-sec-name-left {
		width: 230px;
		height: 37px;
		transform: rotate(180deg);
	}

	.good-sec-name-right {
		width: 230px;
		height: 37px;
	}

	.good-sec-name {
		font-size: 34px;
		font-family: VonwaonBitmap;
		color: #FFFFFF;
		line-height: 40px;
		letter-spacing: 10px;
	}

	.list-sec {
		padding: 25px 25px 0 25px;
	}

	.good-item {
		width: 700px;
		/* height: 600px; */
		background: #FFFFFF;
		border-radius: 10px;
		padding: 20px 20px 35px 20px;
		box-sizing: border-box;
		margin-bottom: 91px;
	}

	.good-item2 {
		width: 700px;
		/* height: 600px; */
		background: #FFFFFF;
		border-radius: 10px;
		padding: 20px 20px 35px 20px;
		box-sizing: border-box;
		margin-bottom: 40px;
	}

	.xian {
		width: 18px;
		height: 48px;
		background: #6DD400;
		border-radius: 9px;
		border: 2px solid #FFFFFF;
		position: absolute;
		box-sizing: border-box;
	}

	.xian-1 {
		top: -33px;
		left: 44px;
	}

	.xian-2 {
		top: -33px;
		right: 44px;
	}

	.xian-3 {
		bottom: -33px;
		left: 44px;
	}

	.xian-4 {
		bottom: -33px;
		right: 44px;
	}

	.good-item-top {
		width: 660px;
		height: 380px;
		background: #F7F7F7;
		border-radius: 10px;
	}

	.good-item-img {
		width: 581px;
		height: 327px;
		margin-left: 39px;
		margin-top: 37px;
	}

	.good-item-num {
		font-size: 26px;
		font-family: VonwaonBitmap;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 49px;
		width: 90px;
		height: 49px;
		background: linear-gradient(142deg, #7CC2FF 0%, #A144FF 100%);
		border-radius: 10px;
		text-align: center;
		position: absolute;
		top: 20px;
		left: 20px;
	}

	.food-item-finish-icon {
		width: 128px;
		height: 43px;
		background: linear-gradient(180deg, #FF9347 0%, #FF6900 100%);
		border-radius: 4px;
		font-size: 26px;
		font-family: VonwaonBitmap;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 43px;
		letter-spacing: 2px;
		text-align: center;
		position: absolute;
		top: 20px;
		left: 20px;
	}

	.good-item-xian {
		width: 660px;
		height: 10px;
		background: linear-gradient(90deg, #7CC2FF 0%, #8F8DFF 51%, #44FFF6 100%);
		border-radius: 5px;
	}

	.mt19 {
		margin-top: 19px;
	}

	.mt12 {
		margin-top: 12px;
	}

	.new-icon {
		width: 60px;
		height: 32px;
	}

	.good-item-name {
		font-size: 30px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 30px;
		letter-spacing: 1px;
		margin-left: 10px;
	}

	.good-item-price {
		font-size: 28px;
		font-weight: 600;
		color: #FA0C0C;
		line-height: 40px;
		letter-spacing: 1px;
		margin-right: 4px;
	}

	.good-item-qi {
		font-size: 20px;
		color: #FA0C0C;
		line-height: 40px 
	}

	.progress-sec {
		width: 660px;
		height: 50px;
		background: #F3F3F3;
		border-radius: 10px;
		margin-top: 12px;
		overflow: hidden;
	}

	.progress-sec-finish {
		display: flex;
		width: 660px;
		height: 50px;
		background: linear-gradient(90deg, #F5F1FF 0%, #AF98FF 100%);
		position: relative;
		overflow: hidden;
	}

	.progress-l {
		position: absolute;
		background: linear-gradient(110deg, transparent 17.5px, #F3F3F3 0);
		left: -17.5px;
		top: 0px;
		width: 677.5px;
		height: 50px;
		border-radius: 0px 0 0 0px;
	}

	.progress-num-sec {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 660px;
		height: 50px;
		padding: 0 13px 0 17px;
		box-sizing: border-box;
	}

	.progress-sec-con {
		font-size: 20px;
		line-height: 50px;
		letter-spacing: 1px;
	}

	.fire-icon {
		width: 13px;
		height: 16px;
		margin-right: 11px;
	}

	.progress-sec-num {
		font-size: 20px;
		font-weight: bold;
		line-height: 28px;
		letter-spacing: 1px;
	}

	.relative {
		position: relative;
	}

	.r-con {
		position: absolute;
		left: 0;
		top: 0;
		background: transparent;
	}

	.progress-sec-finish {
		height: 50px;
		background: linear-gradient(90deg, #F5F1FF 0%, #AF98FF 100%);
		border-radius: 10px 0px 0px 10px;
		padding: 0 13px 0 17px;
		box-sizing: border-box;
		color: #665A8F;
	}

	.progress-sec-finish-end {
		width: 660px;
		height: 50px;
		background: linear-gradient(90deg, #9B66FF 0%, #6236FF 100%);
		border-radius: 10px;
		padding: 0 13px 0 17px;
		box-sizing: border-box;
		color: #FFFFFF;
	}

	.progress-sec-con {
		font-size: 20px;
		line-height: 50px;
		letter-spacing: 1px;
	}

	.fire-icon {
		width: 13px;
		height: 16px;
		margin-right: 11px;
	}

	.progress-sec-num {
		font-size: 20px;
		font-weight: bold;
		line-height: 28px;
		letter-spacing: 1px;
	}

	/* 项目列表结束 */
	.icon-recommend {
		width: 143px;
		height: 52px;
		position: absolute;
		top: 20px;
		left: 13px;
	}

	.recommend-left-border {
		width: 10px;
		height: 342px;
		position: absolute;
		top: 38px;
		left: 0px;
	}

	.recommend-right-border {
		width: 10px;
		height: 377px;
		position: absolute;
		top: 0px;
		right: 0px;
	}

	.recommend-top-border {
		width: 659px;
		height: 10px;
		position: absolute;
		top: 0px;
		left: 0px;
	}

	.recommend-bottom-border {
		width: 659px;
		height: 10px;
		position: absolute;
		bottom: 0px;
		left: 0px;
	}

	.finished {
		width: 200px;
		height: 50px;
		text-align: center;
		line-height: 50px;
		border-radius: 30px;
		background: rgb(98, 54, 255);
		color: #FFFFFF;
		font-size: 24px;
		margin: auto;
	}

</style>

